@import "config/grid.yaml";
@import "config/templates.yaml";
@import "~@nematis/wp-alpha/assets/sass/abstracts/yaml-variables";
@import "assets/sass/init";
@import "~bootstrap/scss/badge";

// === COMPONENTS
@import "../components/box-detail";

body.single-wine {
  .simple-map,
  .simple-map-fake {
    width: 100%;
    height: 170px;

    @include media-breakpoint-up(lg) {
      height: 570px;
    }
  }

  .simple-map-fake {
    background: url("../../images/vignes-haut-170.jpg") 50% 50%;

    @include media-breakpoint-up(lg) {
      background: url("../../images/vignes-haut-570.jpg") 50% 50%;
    }
  }

  .image-wrapper {
    @include media-breakpoint-between(md, xl) {
      max-width: 35%;
    }
  }

  .post-header {
    .appellation,
    .location {
      line-height: 1.7;
    }

    .appellation {
      font-family: $font-family-sans-serif;
    }

    .location {
      display: flex;

      span + span {
        &::before {
          content: ", ";
        }
      }
    }
  }

  .social-share {
    a {
      color: $gray-900;
    }
  }

  .title-wrapper {
    @include media-breakpoint-up(md) {
      position: relative;
      .social-share {
        position: absolute;
        top: 70px;
        right: 0;
      }
    }

    .appellation {
      padding-bottom: 0;
    }

    .country {
      text-transform: uppercase;
    }

  }

  .wine-content {
    & > div {
      margin-top: $spacer * 2;

      @include media-breakpoint-up(lg) {
        margin-top: $spacer * 4;
      }
    }

    h2,
    .h2 {
      padding-bottom: $headings-margin-bottom;
      border-bottom: 1px solid $gray-300;

      &.bolded {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }

  .block-detail + .block-detail {
    margin-top: $spacer;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }

  .wine-others {
    margin-top: $spacer * 2;

    @include media-breakpoint-up(lg) {
      margin-top: $spacer * 4;
    }
  }
}

.wine-color {
  @extend .badge;
  font-size: 88%;
  font-weight: $font-weight-normal;
}

.wine-tasting-list {
  > li {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1.25;

    & + li {
      margin-top: $spacer;
    }

    &::before {
      display: block;
      flex: 0 0 px2rem(55);
      width: px2rem(55);
      height: px2rem(55);
      margin-right: 2rem;
      content: "";

      background-color: $color-prim;
      background-repeat: no-repeat;
      background-position: 50% 50%;

      border-radius: px2rem(12px);

      @include media-breakpoint-up(lg) {
        margin-right: 1rem;
      }
    }
  }

  .wine-tasting-visual::before {
    background-image: url("../../images/eye.svg");
  }

  .wine-tasting-olfactory::before {
    background-image: url("../../images/nose.svg");
  }

  .wine-tasting-taste::before {
    background-image: url("../../images/mouth.svg");
  }
}

.food-pairing-list {
  color: $gray-900;

  > li {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1.25;
  }

  .icon {
    width: 2rem;
    height: 2rem;

    svg {
      max-width: 2rem;
      height: 2rem !important;
    }
  }

  .name {
    margin-left: 1rem;
  }
}

.wine-detail {
  .picto {
    > svg {
      width: 60px;
      height: 60px;
    }
  }
}
