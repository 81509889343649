%svg-small {
  width: 45px;
  height: 45px;
}

.box-detail {
  .picto > svg {
    @extend %svg-small;
  }
}

.box-detail-picto {
  > svg {
    width: 60px;
    height: 60px;
  }

  &.small {
    @extend %svg-small;
  }
}
